.wrap_table {
    
}

.block_table {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid black;
    margin-top: 10px;
}

.table_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    border-bottom: 1px solid #9c63f7a2;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    @media only screen and (max-width: 605px){
        font-size: 12px;
    }
    @media only screen and (max-width: 480px){
        font-size: 10px;
    }

    .table_item-date{
        border-right: 1px solid #9c63f7a2;   
        
    }

    .table_item-emision{
        border-right: 1px solid #9c63f7a2;   
        
    }

    .table_item-rate{
        border-right: 1px solid #9c63f7a2;   
         
    }

    .table_item-air{
        border-right: 1px solid #9c63f7a2;   
           
    }

    p, h3 {
        width: 20%;
        
    }

    // .table_item-p{
    //     border-bottom: 1px solid #9c63f7a2;

    // }

    .table_item-p--border{
        border-right: 1px solid #9c63f78d;   
        // opacity: 0.65; 
    }

    .table_item-balance {
        width: 40%;
    }
}

.table_button_block {
    button {
        border: 1px solid black;
        margin: 10px 10px;
        padding: 5px;
    }
}

// .number_block {
//     display: flex;
//     align-items: center;
//     transition: all;
// }

// .number {
//     padding: 0px 1px;
//     * {
//         padding: 0px 1px;
//         // font-size: 14px;
//     }
// }

// .dot {
//     line-height: 85px;
// }

// .before_number {
//     font-size: 12px;
//     color: grey;
// }

// .after_number {
//     font-size: 12px;
//     color: grey;
// }

// .main_number {

// }

.active_number {
    animation-name: live;
    animation-duration: 0.2s;
    animation-iteration-count: inherit;
    // animation-fill-mode: forwards; /* зупинка анімації на кінцевому стані */
  }
  
  @keyframes live {
    0% {
      padding-top: 10%;
    }

    50% {
        padding-top: 20%;
    }
  
    100% {
        padding-top: 0px;
    }
  }

//   @keyframes live2 {
//     0% {
//         padding-bottom: 0px;
//       }
  
//       50% {
//           padding-bottom: 10%;
//       }
    
//       100% {
//           padding-bottom: 0%;
//       }
//   }