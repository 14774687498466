@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.calc__wraper{
    position: relative;
    max-width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    height: 100vh;
    background: #1F2A3C  0 0/cover no-repeat;
    color: #fff;
    font-family: 'Kanit', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    z-index: 1;
    margin: auto;

}

.background{
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 50%;

}



.calc__head{
    display: flex;
    justify-content: space-between;
    /* background: linear-gradient(269.87deg, rgba(125, 209, 193, 0.05) 0%, rgba(136, 125, 193, 0.05) 100%); */
    z-index: 100;
    padding: 0 5px;
    background: #2a3951;
    position: relative;
    backdrop-filter: blur(2.5px);
    align-items: center;
    z-index: 100;
}

.calc__head-item {
    margin: 0 16px 0 16px;

}

.calc__head .site__name{
    display: block;
    height: 30px;
    /* width: 100%; */
    /* background: url(/public/img/miniLogo.svg) 0 0/cover no-repeat; */
}
.calc__head .contact {
    font-family: 'Kanit';
    font-style: normal;
    text-align: center;
    padding: 8px 37px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    margin: 10px;
    background: rgba(156, 99, 247, 0.21);
    border: 1px solid #8278B9;
    color: #E2DCFF;
    cursor: pointer;
}



.content__wraper{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 100;
}

.content__wraper .logo{
    width: 80%;
    max-width: 600px;
    height: 244px;
    margin: 25px 0 25px 0;
}

.content__wraper .logo img{
    width: 100%;
}

.calc__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    
}

.calc__wrap_row{
    display: flex;
    width: 70%;
    justify-content: space-around;
    margin-bottom: 65px;
}

.calc__wrap-item{
    display: flex;
    flex: 0 1 50%;
    height: 221px;
    max-width: 442px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(31, 42, 60, 0.85);
    box-shadow: -4px -4px 10px #2B3649, 4px 4px 10px #131C2B;
    border-radius: 20px;
}   

.calc__wrap-item h2{
    width: 80%;
    margin-bottom: 35px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */
    color: #9C63F7;
}   
.calc__wrap-item ul{
    display: flex;
    position: relative;
    height: 56px;
    width: 95%;
    background: #2B3649;
    border-radius: 5px;
    align-items: center;
    justify-content: space-around;
    padding: 1% 5%;
    overflow: hidden;
}   




.number_block {
    display: flex;
    align-items: center;
    transition: all;
}



.calc__wrap-item ul .number{
    /* position: absolute; */
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.15em;
    padding: 0px 1px;
    /* padding-top: 39px; */
    
}   

.calc__wrap-item ul .number .dot{
    width: 6px;
    padding: 0;
}

.before_number {
    font-size: 8px;
    color: grey;
    padding-top: 2px;
    line-height: 6px;
    padding-bottom: 6px;
    filter: blur(0.5px);
}

.after_number {
    font-size: 8px;
    color: grey;
    padding-top: 6px;
    line-height: 6px;
    filter: blur(0.5px);
}

.calc__wrap-item ul .number h1{
    padding: 0px 1px;
    width: 10px;
} 

/* .calc__wrap-item ul div .number:nth-child(-n+2){
    color: #7DD1C1;
} */

.purpure {
    color: #7DD1C1;
}

.calc__wrap .calc__wrap_row .air {
    position: relative;
    
}
.calc__wrap .calc__wrap_row .air .debug{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #81ADC0;

}

.emission__wraper_numb{
    /* padding-bottom: 39px; */
}

.rate__wraper_numb{
    padding-left: 35%;
}

/* *,
*:before,
*:after {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
.air__speed{
    position: absolute;
    left: 42%;
    top: 9%;
    scale: 1.3;
}

.air__speed::after{
    content: '%';
    position: absolute;
    top: 117px;
    left: 75px;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    color: #81ADC0;
}


.eclipse-gradient{
    position: absolute;
    left: 8px;
    width: 141.84px;
    height: 140.84px;
    background: url(/public/img/speedair/backgrLine2.svg) 0 0/contain no-repeat;
    z-index: 90;
    
}
.eclipse-gradient-second{
    position: absolute;
    width: 153.84px;
    height: 153.84px;
    background: url(/public/img/speedair/backgrLine.svg) 0 0/contain no-repeat;
    z-index: 90;
    
}
.eclipse-speed{
    position: absolute;
    left: 25px;
    top: 22px;
    width: 104.84px;
    height: 103.84px;
    background: url(/public/img/speedair/data.svg) 0 0/contain no-repeat;
    z-index: 90;
    
}

.eclipse-speed::before{
    content: '';
    position: absolute;
    width: 82.33px;
    height: 82.33px;
    top: 10px;
    left: 12px;
    border-radius: 50%;
    background: #1F2A3C;
    border: 1.17614px solid rgba(255, 255, 255, 0.02);
    z-index: 99;
}

.back__big-circle{
    position: absolute;
    height: 50px;
    width: 50px;
    left: 54px;
    top: 49px;
    border-radius: 50%;
    z-index: 102;
    background: #263245;
    border: 1.17614px solid rgba(255, 255, 255, 0.02);
    box-shadow: -4.70455px -4.70455px 10px rgba(255, 255, 255, 0.05), 4.70455px 4.70455px 10px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px -1px 40px 0px rgba(7,237,210,0.7);
    -moz-box-shadow: 0px -1px 40px 0px rgba(7,237,210,0.7);
    box-shadow: 0px -1px 40px 0px rgba(7,237,210,0.7);
}

.debug {
    position: absolute;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    z-index: 100;
    color: #81ADC0;
    left: 66px;
    top: 63px;
    z-index: 100;
}

.calc__wrap-item .air__title{
    /* font-size: 20px ; */
    padding-bottom: 37px;
}


.calc__footer{
    display: flex;
    justify-content: space-around;
    margin: 0 30px;
}

.emission ul .emission__wraper_numb .number_block .number p{
    font-size: 14px;
   }

.price_and_button__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 40px;
}

.price_button__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.button_buy {
    background-color: transparent;
    border: 1px solid #01b301;
    border-radius: 8px;
    color: #01a301;
    font-weight: 600;
    margin: 0 10px;
    padding: 8px 25px;
    transition: background-color .3s ease,color .3s ease;
    /* box-shadow: 1px 1px 1px 1px #05c105; */
    box-shadow: 0px 1px 17px 0px rgba(0, 130, 36, 0.53), inset 0px 2px 10px 0px rgba(0, 174, 48, 0.56);
}

.button_buy:hover {
    color: white;
    background-color: green;
}

.button_sell {
    background-color: transparent;
    border: 1px solid rgb(255, 17, 17);
    border-radius: 8px;
    color: rgb(255, 17, 17);
    font-weight: 600;
    margin: 0 10px;
    padding: 8px 25px;
    transition: background-color .3s ease,color .3s ease;
    box-shadow: 0px 1px 17px 0px rgba(255, 0, 0, 0.53), inset 0px 2px 10px 0px rgba(255, 0, 0, 0.56);
}

.button_sell:hover {
    color: white;
    background-color: red;
}

.display_currency__wrap {
    position: absolute;
    display: flex;
    right: 10px;
    align-items: center;
    padding-bottom: 3px;
}

.currency_text {
    color: #71bbb0;
    font-size: 22px;
    padding-bottom: 3px;
}

.currency_image {
    /* padding-top: 2px; */
    height: 28px;
}

.display_currency_emission__wrap {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    padding-bottom: 2px;
}

.currency_emmision_image {
    height: 35px;
    padding-top: 6px;
}

.alert_rotate{
    display: none;
}

@media only screen and (max-width: 1850px){
    .calc__wrap_row{
        width: 70%;
    }

}
@media only screen and (max-width: 1410px){
    .calc__wrap_row{
        width: 80%;
    }

}
@media only screen and (max-width: 1153px){
    
    .calc__wrap_row{
        width: 100%; 
        justify-content: center;
        gap: 1%;
    }

    .calc__wrap-item{
        flex: 0 1 50%;
    }

    .back__small-circle{
        left: 62.5%;
    }


}


@media only screen and (max-height: 801px){

    .content__wraper .logo{
        position: absolute;
        top: -120px;
        max-width: 12%;
    }

    .calc__head{
        margin-bottom: 40px;
        height: 80px;
    }

    .content__wraper .logo {
        position: absolute;
        /* width: 20%; */
        top: -22%;
        max-width: 12%;
    }

    .content__wraper .logo img {
        width: 165px;
    }
}


@media only screen and (max-width: 1055px){
    .air__speed{
        scale: 1.2;
        top: 12%;
        left: 43%;
    }

    /* .circle .dot{
        left:53.5%
    } */
}
@media only screen and (min-width: 250px){
    .calc__head{
        padding: 0 50px;
    }
}
