
.debug {
  color: rgba(0, 0, 0, 0);
  font-family: monospace;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
  font-size: 3vw;
  left: 33px;
  top: 36px;
  z-index: 150;

  // @media (max-width: 1153px){
  //   left: 31px;
  //   top: 31px;
  //   font-size: 25px;
  // }

  // @media (max-width: 1055px){
  //   left: 27px;
  //   top: 25px;
  // }
  // @media (max-width: 950px){
  //   top: 21px;
  //   left: 23px;
  //   font-size: 21px;
  // }
  // @media (max-width: 450px){
  //   top: 19px;
  //   left: 22px;
  // }
  // @media (max-width: 393px){
  //   left: 20px;
  // }
  // @media (max-width: 353px){
  //   left: 50%;
  // }
}

.circule-absolute{
  position: absolute;
  z-index: 90;
  width: 100%;
  left: 63px;
  top: 117px;
}

.circle {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 90;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 13%;
  height: 0;
  padding-bottom: 30%;
    top: -2px;
    left: 51%;
    border-radius: 50%;
    width: 15%;
    height: 0;
    padding-bottom: 15%;
  transform: translate3d(-50%, -50%, 0);
  // box-shadow: 0 0 10px rgba(#000, 0.5);
  transition: all;

  // @media (max-width: 1153px){
  //   top: 0px;
  //   left: 51%;
  //   width: 14%;
  //   padding-bottom: 14%;
  // }

  // @media (max-width: 1055px){
  //   top: 0px;
  //   left: 50%;
  //   width: 12%;
  //   padding-bottom: 12%;
  // }
}


/*  circule */
.circle {

  // @media (max-width: 950px){
  //     top: -23px;
  //     left: 50.6%;
  //     width: 10%;
  //     padding-bottom: 10%;
  //   }
  //   @media (max-width: 393px){
  //     top: -23px;
  //     left: 52%;
  //     width: 12%;
  //     padding-bottom: 12%;
  //   }
  //   @media (max-width: 353px){
  //     left: 47%;
  //   }
  
    &:before {
      content: "";
      position: absolute;
      width: 90%;
      height: 90%;
      // background-color: #ecf0f1;
      border-radius: 50%;
      top: 5%;
      left: 5%;
      // box-shadow: inset 0 0 10px rgba(#000, 0.5);
    }
  
  
  
    .dot {
      transition: 1s;
      position: absolute;
      background-color: #07EDD2;
        width: 10%;
        z-index: 90;
        height: 100%;
        left: 42%;
        top: -32px;
      transform: rotate(0deg);
      transform-origin: center bottom;
      clip-path: polygon(44% 0%, 0 45%, 100% 45%);
      -webkit-box-shadow: -16px -16px 300px 200px rgba(7,237,210,0.7);
      -moz-box-shadow: -16px -16px 300px 200px rgba(7,237,210,0.7);
      box-shadow: -16px -16px 300px 200px rgba(7,237,210,0.7);
  
      &:before {
        content: "";
        position: absolute;
        z-index: 90;
        background-color:  rgba(0, 0, 0, 0);
        // box-shadow: 0 0 10px #000;
        width: 200%;
        transform: translate3d(-25%, -25%, 0);
        height: 0;
        padding-bottom: 200%;
        border-radius: 50%;
        cursor: pointer;
      }
  
  
    //   @media (max-width: 1153px){
    //     height: 87%;
    //     left: 48%;
    //     top: -27px;
    //     clip-path: polygon(44% 0%, 0 35%, 100% 35%);
  
    // }
  
    // @media (max-width: 950px){
    //   height: 100%;
    //   left: 46%;
    //   top: -22px;
  
    // }
  
    // @media (max-width: 450px){
    //   height: 115%;
    //   left: 48%;
    //   top: -25px;
    // }
    // @media (max-width: 393px){
    //   height: 115%;
    //   left: 42%;
    //   top: -27px;
    // }
    // @media (max-width: 353px){
    //   height: 100%;
    //   left: 45%;
    //   top: -17px;
    // }
  
    }}