@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.admin__wrap{
    display: flex;
    flex-direction: column;
    background: #1F2A3C;
    height: 100vh;
}

.admin__header{
    display: flex;
    position: relative;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    margin-left: 72px;
    margin-top: 30px;
}

.btn{
    width: 152px;
    height: 40px;
    left: 83px;
    top: 61px;
    background: rgba(156, 99, 247, 0.21);
    border: 1px solid #8278B9; 
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #E2DCFF; 
}

.btn img{
    margin-right: 30px;
}

.logo{
    flex: 0 1 80%;
    
}

.logo_admin{
    /* position: absolute;
    left: 40%;
    top: 0; */
   max-width: 244px;
   max-height: 77px;
}

.admin__content_wraper{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.content__input{
    flex: 0 1 40%;
    margin: 0 30px 0px 70px;
    
}

.admin__h2{
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    text-align: left;
    /* H1 */
    color: #9C63F7;  
    
}

.content__item{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.air{
    flex-direction: row;
    align-items: center;
    position: relative;

}

.air input{
    max-width: 100px;
    height: 55px;
    margin-left: 35px;
    
}

.air .submit{
    margin-left: 70px;
}
.air p{
    position: absolute;
    left: 140px;
    top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    font-size: 18px;
}

.content__item input{
    background: rgba(31, 42, 60, 0.85);
    box-shadow: inset -4px -4px 10px #2B3649, inset 4px 4px 10px #131C2B;
    border-radius: 20px;
    height: 55px;
    border: 1px solid rgba(31, 42, 60, 0.85);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    font-size: 18px;
    padding-left: 20px;
}



.content__history{
    display: flex;
    flex: 0 1 60%;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
}

.content__history h2{
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
}

.tablet{
    width: 100%;
    height: 500px;
    background: rgba(31, 42, 60, 0.85);
    box-shadow: inset -4px -4px 10px #2B3649, inset 4px 4px 10px #131C2B;
    border-radius: 20px;
    margin-right: 30px;
}

.pagination{
    display: flex;
    width: 100%;
    justify-content: flex-end; 
    margin-right: 80px;
    padding: 20px 0;
}

.pagination button{
    display: flex;
    margin-left: 20px;
    width: 45px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.pagination button img{
    margin: 0;
}



@media only screen and (max-width: 1073px){
    .admin__content_wraper{
        flex-direction: column;
        align-items: center;
    }

    .content__input {
        flex: 0 1 100%;
        width: 60%;
        margin: 0;
    }

    .content__history {
        flex: 0 1 100%;
        width: 90%;
        margin-right: 0;
    }

    .tablet{
        margin: 0;
        
    }

    .pagination {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-right: 0;
        padding: 20px 0;
    }
}

@media only screen and (max-width: 650px){

    .content__input {
        width: 90%;
    }
}
@media only screen and (max-width: 480px){

    .admin__header{
        margin-left: 20px;
        width: 90%;
    }

    .air .submit {
        margin-left: 10px;
        margin-top: 0;
    }
}
@media only screen and (max-width: 415px){

    .admin__header{
        flex-direction: column-reverse;
    }

    .btn{
        margin-top: 20px;
    }

    .air input {
        max-width: 81px;
        height: 55px;
        margin-left: 16px;
    }

    .air p {
        left: 112px;
    }
}